import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import fetch from 'isomorphic-fetch';
import { Base64 } from 'js-base64';
import validUrl from 'valid-url';
import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Modalstyle.css';

import config from '../../../config';
import Loader from '../../Loader/Loader';
// import './DisplayWebPage.css';
const env = process.env.NODE_ENV || 'development';

// Hook to mimic setState method when passing in a callback function
const useSetStateCallback = (currentState) => {
    const [ state, setState ] = useState(currentState);
    const callbackRef = useRef(null);

    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current(state);
            callbackRef.current = null;
        }
    }, [ state ]);
    
    const setStateCallback = useCallback((state, callback) => {
        callbackRef.current = callback; 
        setState(state);
    }, []);
    
    return [ state, setStateCallback ];
};

function WebPageViewer() {
    const [ searchTermOrUrl, setSearchTermOrUrl ] = useSetStateCallback('');
    const [ comment, setComment ] = useState(''); 
    const [ browseHistory, setbrowseHistory ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { sessionId } = useSelector(state => state.session);
    const { selectedApplicationTask } = useSelector(state => state.applications);
    const { ApplicationTasks } = useSelector(state => state.applications.applications);

    const proxyServer = `${config[env].URL}`;
    const iframeRef = useRef(null);

    // const [iframeSrc, setIframeSrc] = useState('');
    const fetchHistory = async () => {
        try {
            //console.log('proxyServer:', proxyServer);
            setLoading(true);
            // Clear any previous errors
            setError(null);

            const response = await fetch(`
                ${proxyServer}/browse-history`,
                {
                    method: 'GET',
                    credentials: 'omit',
                    headers: {
                        sessionid: sessionId,
                        appkey: process.env.REACT_APP_APP_KEY
                    },
                }
            );

            const data = await response.json();
            if (data.error) {
                throw new Error(data.error);
            }

            setbrowseHistory(data.result);
            setLoading(false);
        }
        catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        // Initial API call when the component mounts
        fetchHistory();

        const intervalID = setInterval(() => {
            // API call every specified interval - 40 seconds in milliseconds
            fetchHistory();
        }, 30000);

        return () => {
            // Clean up the interval when the component unmounts
            clearInterval(intervalID);
        };
    }, []);

    const fetchData = async (uri, addToHistoryOnly) => {
        // Call the server endpoint to fetch the HTML
        try {
            //console.log('proxyServer:', proxyServer);
            //console.log('uri:', uri);
            //console.log('addToHistoryOnly:', addToHistoryOnly);

            setLoading(true);
            setError(null);

            const response = await fetch(`
                ${proxyServer}/${uri}`,
                {
                    method: 'GET',
                    credentials: 'omit',
                    headers: {
                        sessionid: sessionId,
                        appkey: process.env.REACT_APP_APP_KEY,
                        returnhistory: addToHistoryOnly
                    },
                }
            );

            if (addToHistoryOnly) {
                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                setbrowseHistory(data.result);

            } else {
                const htmlContent = await response.text();
                //console.log(typeof htmlContent);

                const base64Data = Base64.encode(htmlContent);
                const iframe = iframeRef.current;
                iframe.src = `data:text/html;base64,${base64Data}`;
            }
            setLoading(false);
        } 
        catch (err) {
            console.error(err);
            setLoading(false);
            throw err;
        }
    };

    const handleSaveWebpage = async () => {
        // console.log('Comment:', comment);
        // console.log('Chosen Items:', selectedLinks);
        // console.log('selectedApplicationTask:', selectedApplicationTask);
        // console.log('ApplicationTasks:', ApplicationTasks?.[selectedApplicationTask]?.applicationGUID);  
        try {
            if (!comment?.trim()) {
                setComment('');
                return;
            }

            let outcomeMessage  = null;
            try {
                const applicationGUID = ApplicationTasks?.[selectedApplicationTask]?.applicationGUID;
                setLoading(true);
                // Clear any previous errors
                setError(null);

                const options = {
                    sessionId,
                    applicationGUID,
                    comment,
                    // webpageURLs: selectedLinks
                    webpageURLs: [ searchTermOrUrl ]
                };
                // console.log('options:', options);

                const response = await fetch(
                    `${proxyServer}/save-webpage`,
                    {
                        method: 'POST',
                        credentials: 'omit',
                        headers: {
                            'Content-Type': 'application/json',
                            appkey: process.env.REACT_APP_APP_KEY
                        },
                        body: JSON.stringify(options)
                    }
                );

                const data = await response.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                outcomeMessage = 'Web page content saved.';
            }
            catch (err) {
                outcomeMessage = `An error occurred whilst saving the web page content, original error:- ${err.message}`;
                throw err;
            }
            finally {
                // Clear the comment and close the modal
                setComment('');
                toggleModal();
                confirmAlert({
                    title: 'Save Web Page',
                    message: outcomeMessage,
                    buttons: [
                        {
                            label: 'OK',
                            // onClick: () => resetState()
                            onClick: () => null
                        }
                    ]
                });
            }
        }
        catch (err) {
            setError(err.message);
        }
    };

    const toggleModal = () => {
        setComment('');
        setIsModalOpen(!isModalOpen);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    }

    const handleSave = () => {
        toggleModal();

    };

    const handleSearchOrNewPage = async (event, addToHistoryOnly) => {
        try {
            event.preventDefault();

            // console.log('searchTermOrUrl:', searchTermOrUrl);

            if (!searchTermOrUrl?.trim()) {
                setSearchTermOrUrl('', newState => newState);
                return;
            }

            // Check if the input value is a valid URL
            const isURL = validUrl.isWebUri(searchTermOrUrl);

            const uri = isURL
                ? `webpage?url=${encodeURIComponent(searchTermOrUrl)}`
                : `proxy-search?searchTerm=${encodeURIComponent(searchTermOrUrl)}`;

            if (!isURL) {
                setSearchTermOrUrl(`https://www.google.co.uk/search?q=${encodeURIComponent(searchTermOrUrl)}`, newState => newState);
            }

            await fetchData(uri, addToHistoryOnly);
        
            if (addToHistoryOnly) {
                // Check if the input value is a valid URL or a search term
                if (isURL) {
                    window.open(searchTermOrUrl, "_blank");
                } else {
                    window.open(`https://www.google.co.uk/search?q=${encodeURIComponent(searchTermOrUrl)}`, "_blank");
                }
            }
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleInputOption = async (event, addToHistoryOnly) => {
        try {
            await handleSearchOrNewPage(event, addToHistoryOnly);
        }
        catch (err) {
            console.error(err);
            setError(err);
        }
    };

    const handlePreviousOrNext = async (event, direction = 'next') => {
        try {
            event.preventDefault();
            // alert(`handle${direction}, history ${browseHistory}`);
            // console.log(`handle${direction}, history ${browseHistory}`);

            let currentIndex = browseHistory?.indexOf(searchTermOrUrl);

            if (currentIndex === -1) {
                // Find out if there is a slash as the last character
                // If '/' then remove last character
                // If no '/' then append '/' as last character and search again
                let alternateUrl = '';
                if (searchTermOrUrl?.charAt(searchTermOrUrl?.length - 1) === '/') {
                    alternateUrl = searchTermOrUrl?.slice(0, -1);
                } else {
                    alternateUrl = `${searchTermOrUrl}/`;
                }
                currentIndex = browseHistory?.indexOf(alternateUrl);
            }

            let gotoIndex = 0;
            if (currentIndex === -1) {
                // Set index to first or last element of the array depending on direction
                gotoIndex = direction === 'next' ? browseHistory?.length - 1 : 0;   
            } else {
                gotoIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
                if (gotoIndex >= browseHistory?.length) {
                    gotoIndex = 0;
                }
                if (gotoIndex < 0) {
                    gotoIndex = browseHistory?.length - 1;
                }
            }

            setSearchTermOrUrl(browseHistory[ gotoIndex ], newState => newState);
            const uri = `webpage?url=${encodeURIComponent(browseHistory[ gotoIndex ])}`;

            await fetchData(uri, false);
        }
        catch (err) {
            console.error(err);
            setError(err);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', "marginBottom": "5px"  }}>
                <button 
                    className="button-small-top-left-margin"
                    onClick={ (e) => handlePreviousOrNext(e, 'previous') }
                    disabled={ !Array.isArray(browseHistory) || !browseHistory.length }
                >
                    &lt;
                </button>
                <button 
                    className="button-small-top-left-margin"
                    onClick={ (e) => handlePreviousOrNext(e, 'next') }
                    disabled={ !Array.isArray(browseHistory) || !browseHistory.length }
                >
                    &gt;
                </button>
                <fieldset className="form__fieldset__url">
                    <label className="form__label" htmlFor="searchTermOrUrl">
                        URL or Search Term:
                    <input
                        className="form__fieldset__input__url"
                        type="text"
                        id="searchTermOrUrl"
                        placeholder="Internet search or type a URL"
                        value={ searchTermOrUrl }
                        onChange={ (e) => setSearchTermOrUrl(e.target.value, newState => newState) }
                    />
                    </label>
                </fieldset>
                <button 
                    className="button-small-top-left-margin" 
                    onClick={ (e) => handleInputOption(e, false) }
                    disabled={ !searchTermOrUrl }
                >
                    Search
                </button>
                <button 
                    className="button-small-top-left-margin" 
                    onClick={ (e) => handleInputOption(e, true) }
                    disabled={ !searchTermOrUrl }
                >
                    New Page
                </button>
                <button 
                    className="button-small-top-left-right-margin" 
                    onClick={ handleSave }
                    disabled={ !searchTermOrUrl }
                >
                    Save
                </button>
            </div>
            <Loader
                className="loader__spinner"
                active={loading}
                activeName="loader--active"
                loaderName="loader"
                spinnerName="loader__spinner"
			/>

            {/*<iframe src={iframeSrc} title="Web Page Viewer" style={{ width: '100%', height: '500px' }} ></iframe> */}
            <iframe ref={ iframeRef } width="100%" height="600" title="Web Page Viewer" />

            <Modal 
                open={ isModalOpen } 
                onClose={ toggleModal } 
                center
                closeOnOverlayClick={ false }
                // classNames={{ modal: "custommodalform" }}
                closeOnEsc={ false }
            >
                <div className="modal-content">
                    <label>Comment why you are saving this webpage:</label>
                    <textarea 
                        rows="6" 
                        value={ comment } 
                        onChange={ handleCommentChange } 
                    />
                    <button 
                        onClick={ handleSaveWebpage }
                        disabled= { !comment }
                    >
                        Save
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default WebPageViewer;